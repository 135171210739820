/* ==========================================================================
Landing page layout

*****************************************************************************
* div = Landing-page                                                        *
*   *********************************************************************   *
*   * div = container                                                   *   *
*   *   *************************************************************   *   *
*   *   * div = background-images                                   *   *   *
*   *   *                                                           *   *   *
*   *   *  ******************************************************   *   *   *
*   *   *  * div = header-navigation                            *   *   *   *
*   *   *  *                                                    *   *   *   *
*   *   *  ******************************************************   *   *   *
*   *   *                                                           *   *   *
*   *   *  ******************************************************   *   *   *
*   *   *  * div = images gallery                               *   *   *   *
*   *   *  *                                                    *   *   *   *
*   *   *  ******************************************************   *   *   *
*   *   *************************************************************   *   *
*   *                                                                   *   *
*   *   *************************************************************   *   *
*   *   *    div = information                                      *   *   *
*   *   *                                                           *   *   *
*   *   *************************************************************   *   *
*   *********************************************************************   *
*                                                                           *
*   *********************************************************************   *
*   * div = footer                                                      *   *
*   *********************************************************************   *
*                                                                           *
========================================================================== */
.landing-page .container {
  height: 100%;
  max-height: 100%;
}

.landing-page,
.background-images,
.image-gallery,
.image-gallery-content,
.image-gallery-swipe,
.image-gallery-slide-wrapper,
.image-gallery-slides,
.image-gallery-slide {
  height: 100%;
  max-height: 100%;
}

.image-gallery-slide div {
  position: relative;
  height: 100%;
  max-height: 100%;
}
.image-gallery-slide img {
  height: 100%;
  max-height: 100%;
  position: absolute;
  left: 0px;
}

.landing-page
  .container
  .background-images
  .image-gallery
  .image-gallery-content
  .image-gallery-slide-wrapper
  img {
  object-fit: cover;
  z-index: -1;
}
.image-gallery-content .image-gallery-slide .image-gallery-image img {
  background-color: purple;
  max-height: initial;
}
.landing-page .container .background-images .header-navigation {
  /* we should add position absolute if we want that the header scroll..
        I will prefer that the header is always fix: the option login and the logo
        if always visible*/
  position: absolute !important;
  z-index: 2;
}

.landing-page .container .information {
  z-index: 1;
  position: absolute;
  width: 100%;
  left: 0px;
  top: calc(50% - 70px);
}

@media (max-width: 640px) and (orientation: portrait) {
  .landing-page .container .information {
    top: 36%;
  }
}

@media (max-width: 1024px) and (max-height: 1024px) and (orientation: portrait) {
  .landing-page .container .information {
    top: 34%;
  }
}

@media (min-width: 440px) and (max-height: 620px) and (orientation: portrait) {
  .landing-page .container .information {
    margin-top: -10%;
  }
}

.landing-page .fleet-manager,
.landing-page .recipes,
.landing-page .contact {
  color: white !important;
  padding-top: 10%;
  padding-bottom: 10%px;
  border-radius: 0.5rem;
  padding-bottom: 10%;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: -10%;
}

.landing-page .fleet-manager a,
.landing-page .recipes a,
.landing-page .contact a {
  color: white;
}

.landing-page .fleet-manager {
  background-color: #1fc26b !important;
}

.landing-page .recipes {
  background-color: #2a6bb7 !important;
}

.landing-page .contact {
  background-color: #ff5929 !important;
}

.landing-page .fleet-manager:hover a,
.landing-page .fleet-manager:hover {
  color: #1fc26b !important;
}

.landing-page .recipes:hover a,
.landing-page .recipes:hover {
  color: #2a6bb7 !important;
}

.landing-page .contact:hover a,
.landing-page .contact:hover {
  color: #ff5929 !important;
  cursor: pointer;
}

.landing-page .fleet-manager:hover,
.landing-page .recipes:hover,
.landing-page .contact:hover {
  background-color: white !important;
}
