body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button,
input,
optgroup,
select,
textarea,
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Roboto', sans-serif !important;
}

/* ==========================================================================
 Formulars
 ========================================================================== */

/* Input file: necessary to show in the same line the icon browser and the input (upload file)
  ========================================================================== */
.input-file label {
  position: absolute;
}

/*  Formular mobile
  ========================================================================== */
.formular-mobile .no-border {
  background-color: white !important;
  text-decoration: underline !important;
  padding-left: 0 !important;
}

.formular-mobile .field .error input:not([type='checkbox']):focus,
.formular-mobile .field .error textarea:focus {
  border-color: #db2828;
}

/*  Formular follow the material design specification
  ========================================================================== */
.formular-material-design .label-input-file,
.label-input-file {
  font-weight: 700 !important;
}

.formular-material-design .label-input-file,
.label-input-file span {
  font-weight: normal;
  font-size: 90%;
}

.input-file .input-file-button {
  position: relative;
  top: 0px;
}

.input-file .input-file-button-ie-mobile {
  position: relative;
  top: 1px;
}

.formular-material-design .input-file,
.input-file {
  position: relative;
}

.formular-material-design .input-file .file-name,
.input-file .file-name {
  margin-left: 2px !important;
  padding-bottom: 9px !important;
  padding-left: 40px !important;
}

.formular-material-design .input-file .folder-icon,
.input-file .folder-icon {
  display: initial;
}

.formular-material-design .input-file .folder-icon:hover,
.input-file .folder-icon:hover {
  display: initial;
}

.formular-material-design .input-file .input-file-button {
  margin-right: 0px;
  display: initial;
}

.formular-material-design .no-border {
  background-color: white !important;
  text-decoration: underline !important;
  padding-left: 0 !important;
}

.formular-material-design .label-input-file,
.formular-material-design .label-input-fields,
.formular-mobile .label-input-fields {
  color: grey;
  font-size: 80%;
  top: -1rem;
  left: 0;
  font-weight: 700;
}

/* Material design specification for formular
 ========================================================================== */
.formular-material-design .field {
  position: relative;
  width: 100%;
}

.formular-material-design .field {
  position: relative;
  width: 100%;
}

.formular-material-design .ui.form .fields {
  margin: 0 !important;
}

.formular-material-design .field:not(.checkbox),
.formular-material-design .fields:not(.checkbox) {
  margin-top: 1rem;
  margin-bottom: 1.7rem;
}

.formular-material-design .field label {
  color: lightgrey;
  top: 0.7rem;
  transition: 0.2s ease-in;
  position: absolute;
}

.formular-material-design .field .ui.checkbox label {
  position: initial !important;
}

.formular-material-design.icon .field .checkbox {
  left: 0.9rem;
}

.formular-material-design.icon .field label {
  left: 2.7rem;
  color: lightgrey;
}

/* CSS styles for the label when the input is empty */
.formular-material-design
  .field
  input[type='password']:valid:placeholder-shown
  ~ label,
.formular-material-design
  .field
  input[type='text']:valid:placeholder-shown
  ~ label,
.formular-material-design .field input[type='password']:invalid ~ label,
.formular-material-design .field input[type='text']:invalid ~ label,
.formular-material-design
  .field
  input[type='password']:valid:placeholder-shown
  ~ label,
.formular-material-design .field textarea:valid:placeholder-shown ~ label,
.formular-material-design .field textarea:invalid ~ label {
  top: 0.7rem;
  color: lightgrey;
  font-size: 100%;
}

/* CSS style for label when the input get the focus*/
.formular-material-design .field .input input ~ label,
.formular-material-design
  .field
  input[type='password']:valid:not(:placeholder-shown)
  ~ label,
.formular-material-design
  .field
  input[type='text']:valid:placeholder-shown:focus
  ~ label,
.formular-material-design
  .field
  .left
  .icon
  input[type='text']:valid:placeholder-shown:focus
  ~ label,
.formular-material-design
  .field
  input[type='password']:valid:placeholder-shown:focus
  ~ label,
.formular-material-design .field textarea:valid:placeholder-shown:focus ~ label,
.formular-material-design .field textarea:invalid:focus ~ label {
  top: -1rem;
  left: 0;
  font-size: 80%;
  color: grey;
}

.formular-material-design .field.select {
  display: flex;
  margin-top: 2rem;
}

.formular-material-design .field.select .input-select-no-selected {
  top: 0.7rem !important;
  color: lightgrey !important;
  font-size: 100% !important;
  margin-left: -0.5rem;
}

.formular-material-design .field.select:after {
  align-self: center;
  color: lightgrey;
  content: '\f107';
  font-family: 'Icons';
  margin-left: -1rem;
}

.formular-material-design .field.select select {
  background: transparent;
  border: none;
  border-bottom: 2px solid lightgrey;
  padding: 0.7rem 2rem 0.7rem 0.7rem;
  position: relative;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: 0.2s ease-in;
}

.formular-material-design .field.select select:focus {
  outline: none !important;
  border-bottom: 2px solid #2a6bb7 !important;
}

/* Formular Input type=select focus or content */
.formular-material-design .field.select select:focus ~ label,
.formular-material-design .field.select select:placeholder-shown ~ label,
.formular-material-design .field.select select:valid ~ label {
  color: gray;
  font-size: 80%;
  top: -1rem;
  left: 0;
}

.formular-material-design .field input:not([type='checkbox']),
.formular-material-design .field textarea {
  background: transparent;
  border: none;
  border-bottom: 2px solid lightgrey;
  border-radius: 0 !important;
  padding: 0.7rem;
  transition: 0.2s ease-in;
}

.formular-material-design .field input:not([type='checkbox'])[value=' '],
.formular-material-design .field textarea[value=' '] {
  background: grey !important;
}

.formular-material-design .field input:not([type='checkbox']):focus ~ label,
.formular-material-design .field textarea:focus ~ label,
.formular-material-design .field textarea:valid ~ label,
.formular-material-design .field input:not([type='checkbox']):valid ~ label {
  color: grey;
  font-size: 80%;
  top: -1rem;
}

.formular-material-design .field input:not([type='checkbox']):focus,
.formular-material-design .field textarea:focus {
  border-bottom: 2px solid #2a6bb7;
  outline: none;
}

.formular-material-design .field input.error:not([type='checkbox']):focus,
.formular-material-design .field .error input:not([type='checkbox']):focus,
.formular-material-design .field textarea.error:focus {
  border-bottom: 2px solid #db2828;
  outline: none;
}

.formular-material-design .field.checkbox {
  align-items: flex-start;
  align-content: flex-start;
  display: flex;
  margin: 0.7rem 0;
  position: relative;
}

.formular-material-design .field.checkbox input[type='checkbox'] {
  padding: 0.7rem;
  border: 2px solid lightgrey;
  transition: 0.2s ease-in;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.formular-material-design .field.checkbox input[type='checkbox']:focus {
  outline: none;
}

.formular-material-design .field.checkbox input[type='checkbox']:before {
  color: grey;
  content: '';
  transition: 0.2s ease-in;
}

.formular-material-design .field.checkbox input[type='checkbox']:checked {
  padding: 0.6rem 0.35rem;
  margin: -0.3rem 0.1rem 0 0.7rem;
  border-left: none;
  border-top: none;
  transform: rotate(45deg);
}

.formular-material-design .field.checkbox label {
  font-size: initial;
  position: static;
  margin-left: 1rem;
  align-self: center;
}

/* SemanticUI overrides */

.ui.inverted > .ui.modal > .close {
  color: #000;
}

.ui.image.centered img {
  margin-left: auto;
  margin-right: auto;
}
/** Fixes materialUI overlaps */
.ui {
  line-height: 1.4285em;
}
